import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import moment, { Moment } from 'moment';

@Component({
    selector: 'circle-timer',
    templateUrl: './circle-timer.html',
    styleUrls: ['./circle-timer.scss'],
})
export class CircleTimer implements OnInit, OnDestroy {
    @Input() public time = 60;
    @Input() public start: string;
    @Input() public endTime: Moment;

    timeRemaining = 0;
    startTime: Moment;

    subscription: Subscription;
    formatTitle: (percent: number) => string;

    constructor() {}

    ngOnInit() {
        // TODO: Focus target <09-10-20, Liaan> //
        if (this.start) {
            this.startTime = moment.utc(this.start);
        } else {
            this.startTime = moment.utc();
        }
        if (!this.endTime) {
            this.endTime = this.startTime.clone();
            this.endTime.add(this.time, 'seconds');
        }
        this.updateTimer();
        this.formatTitle = this.formatRemaining.bind(this);
        this.subscription = interval(1000).subscribe(
            this.updateTimer.bind(this)
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    updateTimer() {
        this.timeRemaining = this.endTime.diff(moment.utc(), 'seconds');
        this.timeRemaining =
            (Math.max(this.timeRemaining, 0) / this.time) * 100;
    }

    formatRemaining(percent) {
        const seconds = ((percent / 100) * this.time).toFixed(0);
        return seconds;
    }
}
