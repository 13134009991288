<circle-progress
    style="display:flex;"
    [percent]="timeRemaining"
    [radius]="25"
    [titleFormat]="formatTitle"
    [showTitle]="true"
    [showUnits]="false"
    [showSubtitle]="false"
    [showTitle]="true"
    [showInnerStroke]="false"
    [outerStrokeColor]="'#78C000'"
    [clockwise]="false"
    [animation]="false"
    ></circle-progress>
