import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { AuthService } from '../../../components/auth/auth.service';
import type { User } from '../../../services/user.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-lock-screen',
    templateUrl: './lock-screen.component.html',
    styleUrls: ['../../../vendor/styles/pages/authentication.scss'],
})
export class LockScreenComponent implements OnInit {
    user: User;

    errors = { login: undefined };
    credentials = {
        password: '',
    };

    constructor(
        private appService: AppService,
        private authService: AuthService,
        private router: Router
    ) {
        this.appService.pageTitle = 'Lock Screen';
    }

    ngOnInit() {
        this.authService
            .getCurrentUser()
            .then((user) => {
                if (user) {
                    this.user = user;
                } else {
                    return this.router.navigate(['authentication/login']);
                }
            })
            .catch((err) => {
                console.error(err);
                return this.router.navigate(['authentication/login']);
            });
    }

    login(form) {
        if (form.invalid) return;

        return this.authService
            .login({
                email: this.user.email,
                password: this.credentials.password,
            })
            .then(() => {
                // Logged in, redirect to home
                this.router.navigate(['accPlaceholder/home']);
            })
            .catch((err) => {
                this.errors.login = err.error.message;
            });
    }
}
