// @flow
import { Injectable, Injector } from '@angular/core';

import { BaseService, Model } from './base.service';


export interface Hierarchy {
    name: string;
    active: boolean;
    concurrency: number;
    members: string[];
}


export interface InterlockingGroup extends Model {
    name: string;
    active: boolean;
    concurrency: number;
    hierarchies: Hierarchy[];
}

@Injectable()
export class InterlockingGroupService extends BaseService<InterlockingGroup> {
    modelName = 'interlockingGroups';
    constructor(injector: Injector) {
        super(injector);
    }
}
