import 'mainStyle';

import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

const options = {
    preserveWhitespaces: false,
};

declare global {
    interface Window {
        cookieconsent: any;
        __CSP_NONCE: any;
    }
}

require('cookieconsent');

const __webpack_nonce__ = window.__CSP_NONCE;
const cc = window.cookieconsent.initialise({
    palette: {
        popup: {
            background: '#252e39',
        },
        button: {
            background: '#14a7d0',
        },
    },
    content: {
        href: '/assets/policy/privacy.html',
    },
});

// depending on the env mode, enable prod mode or add debugging modules
if (process.env.NODE_ENV === 'production') {
    enableProdMode();
} else {
    options.preserveWhitespaces = true;
}

export function main(): Promise<any> {
    return platformBrowserDynamic().bootstrapModule(AppModule, options);
}

if (document.readyState === 'complete') {
    main();
} else {
    document.addEventListener('DOMContentLoaded', main);
}
