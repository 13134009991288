import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountService } from './account.service';
import { BaseService } from './base.service';
import { ContactService } from './contact.service';
import { EventService } from './event.service';
import { InterlockingGroupService } from './interlockingGroup.service';
import { RuleService } from './rule.service';
import { ScheduleService } from './schedule.service';
import { SiteService } from './site.service';
import { TaskService } from './task.service';
import { ThingService } from './thing.service';
import { UnitService } from './unit.service';
import { UserService } from './user.service';

@NgModule({
    imports: [CommonModule],
    providers: [
        AccountService,
        BaseService,
        ContactService,
        EventService,
        InterlockingGroupService,
        RuleService,
        RuleService,
        ScheduleService,
        SiteService,
        TaskService,
        ThingService,
        UnitService,
        UserService,
    ],
})
export class ServicesModule {}
