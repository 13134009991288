import { NgModule } from '@angular/core';
import { LoginGuard } from './login-guard.service';
import { VerifyGuard } from './verify-guard.service';
import { ConsentModule } from '../consent/consent.module';

@NgModule({
    providers: [LoginGuard, VerifyGuard],
    imports: [ConsentModule],
})
export class AuthModule {}
