import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../components/directives.module';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { EmailConfirmComponent } from './email-confirm/email-confirm.component';
import { EmailRequestComponent } from './email-request/email-request.component';
import { LockScreenComponent } from './lock-screen/lock-screen.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { TOTPModule } from '../../components/totp/totp.module';
import { Ng9PasswordStrengthBarModule } from 'ng9-password-strength-bar';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { SafePipeModule } from '../../components/safe-pipe/safePipe.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DirectivesModule,

        AuthenticationRoutingModule,
        SafePipeModule,
        TOTPModule,
        Ng9PasswordStrengthBarModule,
    ],
    declarations: [
        LoginComponent,
        RegisterComponent,
        EmailConfirmComponent,
        EmailRequestComponent,
        LockScreenComponent,
        PasswordResetComponent,
    ],
})
export class AuthenticationModule {}
