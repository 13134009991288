import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CircleTimer } from './circle-timer.component';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
    declarations: [CircleTimer],
    imports: [CommonModule, NgCircleProgressModule.forRoot({})],
    exports: [CircleTimer],
})
export class CircleTimerModule {}
