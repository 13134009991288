import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { EmailConfirmComponent } from './email-confirm/email-confirm.component';
import { EmailRequestComponent } from './email-request/email-request.component';
import { LockScreenComponent } from './lock-screen/lock-screen.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { RegisterComponent } from './register/register.component';

import { LoginGuard } from '../../components/auth/login-guard.service';
// *******************************************************************************
//

@NgModule({
    imports: [
        RouterModule.forChild([
            { path: 'login', component: LoginComponent },
            // TODO: Implement registration process <25-06-20, Liaan> //
            //{ path: 'register', component: RegisterComponent },
            { path: 'lock', component: LockScreenComponent },
            { path: 'password-reset', component: PasswordResetComponent },
            {
                path: 'password-reset/:userId/:token',
                component: PasswordResetComponent,
            },
            {
                path: 'email-confirm/:userId/:token',
                component: EmailConfirmComponent,
            },
            {
                path: 'email-request',
                canActivate: [LoginGuard],
                component: EmailRequestComponent,
            },
            { path: '', redirectTo: 'login', pathMatch: 'full' },
        ]),
    ],
    exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
