import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, Model } from './base.service';

export interface IOObject {
    portId: string;
    type: string;
    value: boolean;
    tamper: boolean;
}

export interface Heartbeat {
    timestamp: Date;
    boot: boolean;
    unit: string;
    ioStatus: IOObject[];
}

export interface IOController {
    _id: string;
    enabled: boolean;
    type: string;
    ports?: [{ type: string }];
    alarmSupport?: boolean;
}

export interface Unit extends Model {
    mac: string;
    imei: string;

    connId: Buffer;
    cAccountId: Buffer;
    cAccountName: string;
    cName: string;

    name: string;
    description: string;
    serial: string;

    account: string;
    lastActivity: Date;
    lastHB: Heartbeat[];

    online: boolean;

    ioControllers: IOController[];

    type: string;
    site: string;
    groups?: string[];
}

@Injectable()
export class UnitService extends BaseService<Unit> {
    modelName = 'units';

    constructor(injector: Injector) {
        super(injector);
    }

    moveUnits(unitIds: string[], toAccount: string): Observable<any> {
        return this.http.post<any>(`/api/units/moveUnits`, {
            unitIds,
            toAccount,
        });
    }
}
