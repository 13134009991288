import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { AuthService } from '../../../components/auth/auth.service';
import { UserService } from '../../../services/user.service';
import type { User } from '../../../services/user.service';

@Component({
    selector: 'app-email-request',
    templateUrl: './email-request.component.html',
    styleUrls: ['../../../vendor/styles/pages/authentication.scss'],
})
export class EmailRequestComponent implements OnInit {
    user: User;
    constructor(
        private appService: AppService,
        public authService: AuthService,
        private userService: UserService
    ) {
        this.appService.pageTitle = 'Confirm Email';
    }

    ngOnInit() {
        this.authService.getCurrentUser().then((user) => {
            this.user = user;
            this.userService.requestVerificationEmail(this.user._id).subscribe(
                (val) => console.debug(val),
                (err) => console.error(err)
            );
        });
    }

    resendEmail() {
        this.userService.requestVerificationEmail(this.user._id).subscribe(
            (val) => console.debug(val),
            (err) => console.error(err)
        );
    }
}
