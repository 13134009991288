import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-email-confirm',
    templateUrl: './email-confirm.component.html',
    styleUrls: ['../../../vendor/styles/pages/authentication.scss'],
})
export class EmailConfirmComponent implements OnInit {
    credentials = {
        userId: '',
        token: '',
    };
    verified = false;
    error = false;
    constructor(
        private appService: AppService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private toastrService: ToastrService
    ) {
        this.appService.pageTitle = 'Confirm Email';
    }

    ngOnInit() {
        this.route.paramMap.subscribe(
            (params: ParamMap) => {
                if (params.has('userId') && params.has('token')) {
                    this.credentials.userId = params.get('userId');
                    this.credentials.token = params.get('token');
                    this.userService.verifyEmail(this.credentials).subscribe(
                        (val) => {
                            this.verified = true;
                        },
                        (err) => {
                            this.toastrService.error('Verification failed');
                            console.error(err);
                            this.router.navigate([
                                '/authentication/email-request',
                            ]);
                        }
                    );
                }
            },
            (err) => console.error(err)
        );
    }

    login() {
        this.router.navigate(['/authentication/login']);
    }
}
