import {
    Component,
    Input,
    HostBinding,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AppService } from '../../app.service';
import { LayoutService } from '../../layout/layout.service';
import { AuthService } from '../../../components/auth/auth.service';
import { Router } from '@angular/router';
import { TaskService, Task } from '../../../services/task.service';
import type { SyncEvent } from '../../../components/socket/socket.service';
import { Subscription } from 'rxjs';
import { BaseQuery, SubQuery } from '../../../services/base.service';
import { remove } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import {
    defineAbilityForAccount,
    Actions,
    Subjects,
} from '../../../components/auth/ability-builder';

import { Ability } from '@casl/ability';

interface Account {
    name: string;
    ref: string;
}

@Component({
    selector: 'app-layout-navbar',
    templateUrl: './layout-navbar.component.html',
    styleUrls: [
        '../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
    ],
})
export class LayoutNavbarComponent implements OnInit {
    isExpanded = false;
    isRTL: boolean;
    user;

    @ViewChild('notificationsDropdown', { static: true })
    notificationsDropdown: NgbDropdown;

    @Input()
    sidenavToggle = true;
    searchAccount = '';
    currentAccount: Account;
    activeTasks: Task[] = [];
    subscription: Subscription;

    @HostBinding('class.layout-navbar') hostClassMain = true;

    constructor(
        private appService: AppService,
        private layoutService: LayoutService,
        public authService: AuthService,
        private router: Router,
        private ability: Ability,
        private taskService: TaskService,
        private toastrService: ToastrService
    ) {
        this.isRTL = appService.isRTL;
        this.authService = authService;
        this.ability.update(this.authService.abilityRules);
    }

    ngOnInit(): void {
        this.user = this.authService.getCurrentUserSync();
        this.user.accounts.sort((a, b) => a.name.localeCompare(b.name));
        this.currentAccount = this.authService.currentAccount;
        if (this.ability.can('manage', 'RemoteAuth')) {
            this.updateData();

            this.subscription = this.taskService
                .register(
                    this.activeTasks,
                    {
                        addCreated: false,
                    },
                    `task:${this.authService.currentAccount.ref}:*`
                )
                .subscribe(this.handleTask.bind(this));
        }
    }

    ngOnDestroy() {
        if (this.subscription) this.subscription.unsubscribe();
    }

    updateData() {
        const query: BaseQuery = {
            by: 'createdAt',
            order: 'desc',
            params: [
                {
                    field: 'type',
                    value: 'RemoteAuthRequest',
                    type: 'string',
                },
                {
                    field: 'status',
                    value: 'Requested',
                    type: 'string',
                },
            ],
        };
        this.taskService.getList(query).subscribe(
            (tasks) => {
                this.activeTasks.length = 0;
                tasks.forEach((a) => this.activeTasks.push(a));
            },
            (err) => console.error(err)
        );
    }

    currentBg(): string {
        return `bg-${this.appService.layoutNavbarBg}`;
    }

    toggleSidenav(): void {
        this.layoutService.toggleCollapsed();
    }

    setCurrentAccount(account: Account) {
        const newUrl = this.router.url.replace(
            this.currentAccount.ref,
            account.ref
        );
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.navigateByUrl(newUrl);
    }

    handleTask(event: SyncEvent) {
        const newTask = event.item as Task;
        //The default filter sort is selected.  Add event to start of array.
        if (newTask.type === 'RemoteAuthRequest') {
            if (event.event == 'create') {
                const activeToast = this.toastrService.info(
                    'New Access Requested'
                );
                activeToast.onTap.subscribe(() => {
                    this.notificationsDropdown.open();
                });
                this.activeTasks.unshift(newTask);
            } else if (event.event === 'update') {
                if (newTask.status !== 'Requested') {
                    remove(this.activeTasks, ['_id', newTask._id]);
                }
            }
        }
    }

    clearTask(task) {
        remove(this.activeTasks, ['_id', task._id]);
    }

    grantAccess(taskId: string) {
        this.taskService.actionTask(taskId, true).subscribe(
            () => {
                this.toastrService.info('Access granted');
            },
            (err) => {
                console.error(err);
                this.toastrService.error('Access grant failed');
            }
        );
    }

    denyAccess(taskId: string) {
        this.taskService.actionTask(taskId, false).subscribe(
            () => {
                this.toastrService.info('Access denied');
            },
            (err) => {
                console.error(err);
                this.toastrService.error('Access deny failed');
            }
        );
    }
}
