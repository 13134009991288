// @flow
import { Injectable, Injector } from '@angular/core';

import type { MetaData } from './site.service';
import { BaseService, Model } from './base.service';
import { Observable } from 'rxjs';

export interface Schedule extends Model {
    active?: boolean;
    account?: string;
    name: string;
    description: string;
    startTime?: Date;
    endTime?: Date;
    duration?: number; //Seconds
    rruleSet?: string[];
    metaData?: MetaData[];
}

@Injectable()
export class ScheduleService extends BaseService<Schedule> {
    modelName = 'schedules';

    constructor(injector: Injector) {
        super(injector);
    }
}
