import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../app.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LogoService } from '../../../components/logoManager/logoManager.service';
import zxcvbn from 'zxcvbn';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['../../../vendor/styles/pages/authentication.scss'],
})
export class PasswordResetComponent implements OnInit {
    @ViewChild('passwordResetForm') passwordResetForm: NgForm;
    hasToken = false;
    token: string;
    userId: string;
    linkSent = false;

    credentials = {
        email: '',
        userId: '',
        password: '',
        token: '',
        confirmPassword: '',
    };

    feedback :{
        warning?: string
        suggestions?: string[]
    };
    valid = false;

    constructor(
        private appService: AppService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private toastrService: ToastrService,
        public logoService: LogoService
    ) {
        this.appService.pageTitle = 'Password Reset';
    }

    ngOnInit() {
        this.route.paramMap.subscribe(
            (params: ParamMap) => {
                if (params.has('userId') && params.has('token')) {
                    this.credentials.userId = params.get('userId');
                    this.credentials.token = params.get('token');
                    this.hasToken = true;
                }
            },
            (err) => console.error(err)
        );
    }

    requestReset() {
        this.userService
            .requestForgotPasswordEmail(this.credentials.email)
            .subscribe(
                (val) => {
                    this.linkSent = true;
                },
                (err) => {
                    console.error(err);
                }
            );
    }

    resetPassword() {
        this.userService.resetPassword(this.credentials).subscribe(
            (val) => {
                this.toastrService.success('Password reset.');
                this.router.navigate(['/authentication/login']);
            },
            (err) => {
                this.toastrService.error('Password reset failed.');
                this.router.navigate(['/authentication/password-reset']);

                console.error(err);
            }
        );
    }

    doLog() {
        console.debug(this);
    }

    onPasswordChange(event){
        
        const result = zxcvbn(this.credentials.password);
        
        if(result && result.score < 2){
            this.feedback = result.feedback;
            this.valid = false;
        }else{
            this.feedback = undefined;
            this.valid = true;
        }
    }
}
