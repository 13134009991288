import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../components/auth/auth.service';
import { UserService } from '../../services/user.service';
import type { User } from '../../services/user.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'consent-modal',
    templateUrl: './consent.modal.html',
    styleUrls: ['./consent.modal.scss'],
})
export class ConsentModal implements OnInit {
    @Input() public user: User;

    constructor(
        public authService: AuthService,
        public userService: UserService,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit() {}

    ok() {
        const update = {
            consent: {
                privacy: new Date(),
                terms: new Date(),
                disclaimer: new Date(),
            },
        };

        this.userService
            .saveMe(update)
            .toPromise()
            .then((user) => {
                this.authService.currentUser = user;
                this.activeModal.close();
            })
            .catch((err) => {
                console.error(err);
            });
    }

    doLog() {
        console.debug(this);
    }

    cancel() {
        this.activeModal.close();
    }
}
