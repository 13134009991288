// @flow
import { Injectable, Injector } from '@angular/core';

import { BaseService, Model } from './base.service';
import type { ContactRef } from './contact.service';

interface SiteUnit {
    unit: string;
    configuration?: any;
}

export interface MetaData {
    key: string;
    val: string;
}

export interface Function {
    purpose:
        | 'LockControl'
        | 'DoorSensor'
        | 'LockSensor'
        | 'EmergencyExit'
        | 'PartitionControl'
        | 'Tamper'
        | 'Disabled'
        | 'StatusIndicator';
    inverse?: boolean;
    controllerId?: string;
    portIndex?: string;
    portType?: 'sink' | 'source';
    partitions?: number[];
    requiresPort?: boolean;
    disarmProcedure?: DisarmProcedure;
    snapshotProcedures?: SnapshotProcedure[];
}

export interface DisarmProcedure {
    enabled?: boolean;
    autoArmDelay?: number;
    idleArmTime?: number;
    ignoreScheduleArm?: boolean;
    periodicSnapshots?: {
        enabled?: boolean;
        partitions?: number[];
        snapshotPeriod?: number;
    };
}

export interface SnapshotProcedure {
    enabled?: boolean;
    triggers?: string[];
    partitions?: number[];
    snapshotCount?: number;
    initialDelay?: number;
    burstDelay?: number;
    maxProcedureDuration?: number;
}

export interface DoorConfig {
    multiAuth?: {
        type?: string;
    };
    autoArmDelay?: number;
    autoArmDelayEnabled?: boolean;
    autoLockDelay?: number;
    autoLockDelayEnabled?: boolean;
    businessHoursSchedules?: string[];
    businessHoursSchedulesEnabled?: boolean;
    masterAuth?: boolean;
}

export interface Door {
    functions?: Function[];
    unit?: string;
    type: string;
    subType?: string;
    name: string;
    enabled: boolean;
    _id?: string;
    id?: number;
    partitions: number[];
    siteName?: string;
    groups?: string[];
    locked?: boolean;
    closed?: boolean;
    armed?: boolean;
    configuration?: DoorConfig;
    metaData: MetaData[];
    contacts: ContactRef[];
}

//TODO: Add Door and Unit types
export interface Site extends Model {
    name: string;
    account: string;
    doors?: Door[];
    units?: SiteUnit[];
    groups?: string[];
    address?: string[];
    location?: {
        type: string;
        coordinates: number[];
    };
    metaData: MetaData[];
    contacts: ContactRef[];
    enableContacts: boolean;
}

@Injectable()
export class SiteService extends BaseService<Site> {
    modelName = 'sites';
    constructor(injector: Injector) {
        super(injector);
    }
}
