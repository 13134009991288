// @flow
import { Injectable, Injector } from '@angular/core';
import { BaseService, Model } from './base.service';
//Re-export
import { EventTypes } from '../app/app.constants';

export interface Event extends Model {
    _id?: string;
    account?: string;
    accountName?: string;
    device?: string;
    deviceName?: string;
    door?: string;
    doorName?: string;
    reason?: string;
    site?: string;
    siteName?: string;
    success?: boolean;
    timestamp?: Date;
    type?: string;
    unit?: string;
    unitName?: string;
    user?: string;
    userName?: string;
}

@Injectable()
export class EventService extends BaseService<Event> {
    modelName = 'events';

    constructor(injector: Injector) {
        super(injector);
    }
}
export { EventTypes };
