// @flow
import { Injectable, Injector } from '@angular/core';

import { BaseService, Model } from './base.service';

export interface ContactRef {
    contactId?: string;
    supportType?: string;
}

export interface Contact extends Model {
    name: string;
    account: string;
    description?: string;
    number: string;
    active: boolean;
}

@Injectable()
export class ContactService extends BaseService<Contact> {
    modelName = 'contacts';
    constructor(injector: Injector) {
        super(injector);
    }
}
