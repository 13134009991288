import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LogoService {
    domain: string;
    domainLogo: string;
    domainIcon: string;
    lightLogo = false;
    constructor() {
        this.domain = /:\/\/([^\/]+)/
            .exec(window.location.href)[1]
            .split('.')[0];

        if (this.domain.includes('localhost')) {
            this.domain = 'nokey';
        }
        if (this.domain.includes('sbsa')) {
            this.lightLogo = true;
        }
        if (this.domain.includes('total')) {
            this.lightLogo = true;
        }
        if (this.domain.includes('absa')) {
            this.lightLogo = false;
        }
        this.domainLogo = `assets/img/${this.domain}_logo.svg`;
        this.domainIcon = `assets/img/${this.domain}_icon.svg`;
    }
}
