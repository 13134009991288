import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ApplicationRef } from '@angular/core';
import {
    removeNgStyles,
    createNewHosts,
    createInputTransfer,
} from '@angularclass/hmr';
//Not used at this moment, add when needed
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';

// *******************************************************************************
// NgBootstrap

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// *******************************************************************************
// App

import { AppRoutingModule } from './app-routing.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DirectivesModule } from '../components/directives.module';
import { ServicesModule } from '../services/services.module';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';
import { ThemeSettingsModule } from '../vendor/libs/theme-settings/theme-settings.module';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from '../components/http-interceptors';
import { AuthService } from '../components/auth/auth.service';
import { LogoService } from '../components/logoManager/logoManager.service';
import { AccountResolver } from '../components/auth/account.resolver';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CircleTimerModule } from '../components/circle-timer/circle-timer.module';
import 'google-libphonenumber';
import { AgmCoreModule } from '@agm/core';
import { googleAPIKeys } from '../../server/config/environment/shared';
// *******************************************************************************
//

@NgModule({
    declarations: [AppComponent, NotFoundComponent],

    imports: [
        AbilityModule,
        BrowserModule,
        NgbModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        BrowserAnimationsModule,
        // App
        AppRoutingModule,
        LayoutModule,
        ThemeSettingsModule,
        DirectivesModule,
        ServicesModule,
        ToastrModule.forRoot(),
        SweetAlert2Module.forRoot(),
        CircleTimerModule,
        AuthenticationModule,

        AgmCoreModule.forRoot({
            apiKey: googleAPIKeys.maps,
        }),
    ],

    providers: [
        httpInterceptorProviders,
        Title,
        AppService,
        AuthService,
        LogoService,
        AccountResolver,
        { provide: Ability, useValue: new Ability() },
        { provide: PureAbility, useExisting: Ability },
    ],

    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        private appRef: ApplicationRef,
        private modalService: NgbModal
    ) {
        this.appRef = appRef;
    }

    hmrOnInit(store): void {
        if (!store || !store.state) return;
        // inject AppStore here and update it
        // this.AppStore.update(store.state)
        if ('restoreInputValues' in store) {
            store.restoreInputValues();
        }
        // change detection
        this.appRef.tick();
        Reflect.deleteProperty(store, 'state');
        Reflect.deleteProperty(store, 'restoreInputValues');
    }

    hmrOnDestroy(store): void {
        this.modalService.dismissAll();
        const cmpLocation = this.appRef.components.map(
            (cmp) => cmp.location.nativeElement
        );
        // recreate elements
        store.disposeOldHosts = createNewHosts(cmpLocation);
        // inject your AppStore and grab state then set it on store
        // var appState = this.AppStore.get()
        store.state = { data: 'yolo' };
        // store.state = Object.assign({}, appState)
        // save input values
        store.restoreInputValues = createInputTransfer();
        // remove styles
        removeNgStyles();
    }

    hmrAfterDestroy(store): void {
        // display new elements
        store.disposeOldHosts();
        Reflect.deleteProperty(store, 'disposeOldHosts');
        // anything you need done the component is removed
    }
}
