<div class="authentication-wrapper authentication-1 px-4">
  <div class="authentication-inner py-5" *ngIf="user">

    <div class="media align-items-center">
        <img [src]="user.img" alt class="d-block ui-w-50 rounded-circle">
      <div class="media-body ml-3">
        <div class="text-light small font-weight-semibold line-height-1 mb-1">LOGGED IN AS</div>
        <div class="text-xlarge font-weight-bolder line-height-1">{{user.name}}</div>
      </div>
    </div>

    <!-- Form -->
    <form class="mt-4" name="loginForm" (ngSubmit)="login(loginForm)" novalidate #loginForm="ngForm">
      <p class="text-muted small">Your session is timed out. Please enter your password to proceed.</p>
      <div class="input-group">
        <input [(ngModel)]="credentials.password" name="password" type="password" class="form-control" placeholder="Enter your password">
        <div class="input-group-append">
          <button class="btn btn-primary icon-btn" type="submit" [disabled]="!loginForm.form.valid" ><i class="ion ion-md-arrow-forward"></i></button>
        </div>
        <div class="form-group has-error">
          <p class="help-block">{{ errors.login }}</p>
        </div>
      </div>
    </form>
    <!-- / Form -->

    <hr class="my-4">

    <div class="text-center text-muted small">
      Not you? <a href="/authentication/login">Login as a different user</a>
    </div>

  </div>
</div>
