<div class="modal-header" *ngIf="activeModal">
	<h3 class="modal-title"><i class="fas fa-fw fa-key"></i> Two-factor Authentication</h3>
</div>
<div [class.modal-body]="activeModal">
	<form #totpForm="ngForm" style="width:auto;" *ngIf="mfa.enrolled">
		<div class="form-group">
			<div>
				<label class="form-label">
					Enter OTP Code :
				</label>
			</div>
			<div>
				<input style="padding-top:5px;" name="OTP" id="OTP" pattern="^[0-9]{6}$" type="text" #OTPInput="ngModel" placeholder="OTP Code" class="form-control" [(ngModel)]="OTP" required (change)="errorStates.totpFail = false" autocomplete="off">
			</div>
			<div *ngIf="OTPInput.errors && OTPInput.errors.pattern" style="color:maroon" role="alert">
				Please enter a valid OTP.
			</div>
			<div *ngIf="errorStates.totpFail" style="color:maroon" role="alert">
				Invalid OTP provided.
			</div>
			<button [disabled]="totpForm.invalid" class="btn btn-primary" style="margin-top:1em;" (click)="errorStates.totpFail = false;mfaLogin(OTP)"> Sign In</button>
		</div>
	</form>
	<form #enrollForm="ngForm" style="width:auto;" *ngIf="!mfa.enrolled">
		<h4>Setup two-factor authentication</h4>
		<p *ngIf="forced">
            Two-factor authentication has been enabled for this account.  To resume access to this site, two-factor authentication is required.
		</p>
		<br>
		<div class="form-group">
			<p>
			Follow these steps to setup two-factor authentication:
			</p>
			<div>
				<h2><span class="counter-badge">1</span> Download authenticator app</h2>

				<div class="">
					<div class="">
						<i class="fab fa-apple"></i>
						iPhone, iPod Touch, or iPad:
						<a href="https://itunes.apple.com/us/app/authy/id494168017" target="_blank">
							Authy for iOS
						</a>
					</div>
					<div class="">
						<i class="fab fa-android"></i>
						Android devices:
						<a href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank">
							Authy for Android
						</a>
					</div>
				</div>
				<hr style="border-top-color:#ccc">
			</div>
			<div>
				<h2><span class="counter-badge">2</span> Scan QR with the authenticator app</h2>
				<div>
					<button class="btn btn-primary" [disabled]="qrImage" (click)="requestSetup()"> Show QR Code</button>
				</div>
				<br>
				<div *ngIf="qrImage" >
					<label>
						Scan QR Code :
					</label>
					<img [src]="qrImage" alt=""/>
					<br>
					<label>
						Can't scan the code?
						Add code manually :
					</label>
					<span>
                        <span #keySpan>
                            {{key}}
                        </span>
						<button class="btn btn-link" ngbTooltip="Copy token to clipboard." (click)="copyKey(keySpan)" >
							<i class="fas fa-copy" ></i>
						</button>
					</span>
				</div>

				<hr style="border-top-color:#ccc">
			</div>
			<div>
				<h2><span class="counter-badge">3</span> Enter verification code</h2>
				<div >
					<div>
						<label>
							Verify OTP Code :
						</label>
					</div>
					<div>
						<input style="padding-top:5px;" id="verifyOTP" #verifyOTPInput="ngModel" pattern="^[0-9]{6}$" type="text" name="verifyOTP" placeholder="OTP Code" class="form-control" [(ngModel)]="verifyOTP"  autocomplete="off" (change)="errorStates.totpEnrollFail = false" required>
					</div>
					<div *ngIf="verifyOTPInput.errors && verifyOTPInput.errors.pattern" style="color:maroon" role="alert">
						Please enter a valid OTP.
					</div>
					<div *ngIf="errorStates.totpEnrollFail" style="color:maroon" role="alert">
						Invalid OTP provided. Two-factor authentication setup failed.
					</div>
					<button [disabled]="enrollForm.invalid || !qrImage" class="btn btn-primary" style="margin-top:1em;" (click)="errorStates.totpEnrollFail = false;verifyCode()"><i class="fa fa-save"></i> Verify</button>
				</div>
			</div>
		</div>
	</form>
</div>
<div class="modal-footer" *ngIf="activeModal && !forced && !disableCancel">
  <button (click)="cancel()" class="btn btn-warning">Close</button>
</div>
