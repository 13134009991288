import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class LoginGuard implements CanActivate {
    authService;
    router;

    constructor(authService: AuthService, router: Router) {
        this.authService = authService;
        this.router = router;
    }

    canActivate() {
        const self = this;
        return self.authService.isLoggedIn().then((can) => {
            if (!can) {
                return self.authService.logout();
            } else {
                //if (!self.authService.currentAccount) {
                //self.authService.setCurrentAccount();
                //}
                return true;
            }
        });
    }
}
