// @flow
import { Injectable, Injector } from '@angular/core';

import { BaseService, Model } from './base.service';
import { Observable } from 'rxjs';

export interface RuleSettings {
    schedules?: string[];
}

export interface Rule extends Model {
    account?: string;
    door?: string;
    user?: string;
    site?: string;
    userGroup?: string;
    doorGroup?: string;
    siteGroup?: string;
    active?: boolean;
    settingsHash?: string;
    settings?: RuleSettings;
}

export enum NodeType {
    user = 'User',
    door = 'Door',
    site = 'Site',
    userGroup = 'User Group',
    doorGroup = 'Door Group',
    siteGroup = 'Site Group',
}

@Injectable()
export class RuleService extends BaseService<Rule> {
    modelName = 'rules';

    constructor(injector: Injector) {
        super(injector);
    }

    batchUpdates(deleteIds: string[], save: Rule[]): Observable<boolean> {
        return this.http.post<boolean>(`/api/${this.modelName}/batchUpdate`, {
            delete: deleteIds,
            save: save,
        });
    }
}
