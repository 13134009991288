// @flow
import { Injectable, Injector } from '@angular/core';

import { BaseService, Model } from './base.service';

export interface Thing extends Model {
    name: string;
    account: string;
    info: string;
    active: boolean;
}

@Injectable()
export class ThingService extends BaseService<Thing> {
    modelName = 'things';
    constructor(injector: Injector) {
        super(injector);
    }
}
