// @flow
import { Injectable, Injector } from '@angular/core';
import { BaseService, Model } from './base.service';
import { Observable } from 'rxjs';

export interface SubTask {
    type: string;
    status: string;
    data?: any;
}

export interface Task extends Model {
    type:
        | 'ConfigSet'
        | 'HeartbeatRequest'
        | 'LockDoor'
        | 'UnlockDoor'
        | 'DisableDevice'
        | 'RemoteAuthRequest';
    requireAction: boolean;
    reason?: string;
    errorMsg?: string;
    data?: any;
    subTasks?: SubTask[];
    createdAt: Date;
    expire: boolean;
    status: string;
    failReason?: string;
    failedCount?: number;
    user?: string;
    userName?: string;
    account?: string;
    unit?: string;
    unitName?: string;
    site?: string;
    siteName?: string;
    door?: string;
    doorName?: string;
    device?: string;
    deviceName?: string;
}

@Injectable()
export class TaskService extends BaseService<Task> {
    modelName = 'tasks';
    constructor(injector: Injector) {
        super(injector);
    }

    actionTask(taskId: string, grant: boolean): Observable<any> {
        return this.http.post<any>(`/api/tasks/${taskId}/actionRemoteAuth`, {
            grant,
        });
    }
}
