import { Component, HostBinding } from '@angular/core';
import { AppService } from '../../app.service';
import { AuthService } from '../../../components/auth/auth.service';

@Component({
    selector: 'app-layout-footer',
    templateUrl: './layout-footer.component.html',
    styleUrls: ['./layout-footer.scss'],
})
export class LayoutFooterComponent {
    @HostBinding('class.layout-footer') hostClassMain = true;

    constructor(
        private appService: AppService,
        public authService: AuthService
    ) {}

    currentBg(): string {
        return `bg-${this.appService.layoutFooterBg}`;
    }
}
