import { Injectable } from '@angular/core';
import {
    Router,
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import type { Account } from '../../services/account.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountResolver implements Resolve<Account> {
    constructor(private authService: AuthService, private router: Router) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const accountId = route.paramMap.get('accountId');
        return this.authService
            .setCurrentAccount(accountId, state)
            .then((account) => {
                return account;
            });
    }
}
