import { Injectable, EventEmitter, Output } from '@angular/core';
import { UserService } from '../../services/user.service';
import type { Account, User } from '../../services/user.service';
import { SocketService } from '../socket/socket.service';
import { HttpClient } from '@angular/common/http';
import { safeCb } from '../util';
import { userRoles, version } from '../../app/app.constants';
import { Router, ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { find, cloneDeep } from 'lodash';
import { defineAbilityForAccount, Actions, Subjects } from './ability-builder';
import { Ability } from '@casl/ability';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    _currentUser: User;
    @Output() currentUserChanged = new EventEmitter(true);
    userRoles = userRoles || [];
    userPromise;
    currentAccount;
    abilityRules;
    constructor(
        private http: HttpClient,
        private userService: UserService,
        //public ability: Ability,
        private router: Router,
        private socketService: SocketService,
        private route: ActivatedRoute
    ) {}

    /**
     * Check if userRole is >= role
     * @param {String} userRole - role of current user
     * @param {String} role - role to check against
     */
    static hasRole(userRole, role) {
        return userRoles.indexOf(userRole) >= userRoles.indexOf(role);
    }

    get currentUser() {
        return this._currentUser;
    }

    set currentUser(user) {
        this._currentUser = user;
        this.currentUserChanged.emit(user);
    }

    /**
     * Authenticate user and save token
     *
     * @param  {Object}   user     - login info
     * @param  {Function} [callback] - function(error, user)
     * @return {Promise}
     */
    login({ email, password }, callback?) {
        return this.http
            .post('/auth/local', {
                email,
                password,
            })
            .toPromise()
            .then(
                (res: {
                    mfa: { enrolled: boolean };
                    verified: boolean;
                }) => {
                    if (res.mfa) {
                        return {
                            mfaRequired: true,
                            mfa: res.mfa,
                            verified: res.verified,
                        };
                    } else {
                        return this.getCurrentUser();
                    }
                }
            )
            .then((data: User | any) => {
                if (data.mfaRequired) {
                    return data;
                } else {
                    localStorage.setItem('user', JSON.stringify(data));
                    safeCb(callback)(null, data);
                    return data;
                }
            })
            .catch((err) => {
                this.logout();
                safeCb(callback)(err);
                return Promise.reject(err);
            });
    }
    /**
     * Authenticate user and save token using mfa
     *
     * @param  {Object}   user     - login info
     * @return {Promise}
     */
    mfaLogin({ code }) {
        return this.http
            .post('/auth/mfa', {
                code,
            })
            .toPromise()
            .then((res: { mfa: any }) => {
                if (res.mfa) {
                    return { mfaRequired: true, mfa: res.mfa };
                } else {
                    return this.getCurrentUser();
                }
            })
            .then((data: User | any) => {
                if (data.mfaRequired) {
                    return data;
                } else {
                    localStorage.setItem('user', JSON.stringify(data));
                    return data;
                }
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    /**
     * Delete access token and user info
     * @return {Promise}
     */
    logout() {
        if(this.isLoggedInSync()) {
        return this.http
            .post('/auth/logout', {
            })
            .toPromise()
            .then((res: any) => {
                this.socketService.disconnect();
                localStorage.removeItem('user');
                this.currentUser = undefined;
                this.router.navigate(['/authentication/login']);
                return;
            }).catch((err) => {
                console.error(`Logout failed: `, err);
                return Promise.reject(err);
            });
        }
        return this.router.navigate(['/authentication/login']);
    }

    /**
     * Create a new user
     * This should be moved to the user service
     *
     * @param  {Object}   user     - user info
     * @param  {Function} callback - optional, function(error, user)
     * @return {Promise}
     */
    //createUser(user, callback) {
    //return this.userService
    //.save(user)
    //.toPromise()
    //.then((data) => {
    //localStorage.setItem('id_token', data.token);
    //return this.userService.get().toPromise();
    //})
    //.then((_user: User) => {
    //this.currentUser = _user;
    //return safeCb(callback)(null, _user);
    //})
    //.catch((err) => {
    //this.logout();
    //safeCb(callback)(err);
    //return Promise.reject(err);
    //});
    //}

    /**
     * Change password
     *
     * @param  {String}   oldPassword
     * @param  {String}   newPassword
     * @param  {Function} [callback] - function(error, user)
     * @return {Promise}
     */
    changePassword(oldPassword: string, newPassword: string): Promise<void> {
        return this.userService
            .changePassword(this.currentUser._id, oldPassword, newPassword)
            .toPromise();
    }

    /**
     * Gets all available info on a user
     *
     * @return {Promise}
     */
    getCurrentUser() {
        const self = this;
        if (self.currentUser && self.currentUser._id) {
            return Promise.resolve(self.currentUser);
        } else {
            return self.userService
                .get()
                .toPromise()
                .then((user: User) => {
                    self.currentUser = user;
                    return user;
                });
        }
    }

    /**
     * Gets all available info on a user
     *
     * @return {Object}
     */
    getCurrentUserSync() {
        return this.currentUser;
    }

    /**
     * Checks if user is logged in
     * @param {function} [callback]
     * @returns {Promise}
     */
    isLoggedIn() {
        return this.getCurrentUser()
            .then((user) => {
                if (user && user._id) {
                    return user;
                } else {
                    return false;
                }
            })
            .catch((err) => {
                return false;
            });
    }

    /**
     * Checks if user is logged in
     * @returns {Boolean}
     */
    isLoggedInSync() {
        return !!this.currentUser?._id;
    }

    /**
     * Check if a user is an admin
     *
     * @param  {Function|*} [callback] - optional, function(is)
     * @return {Promise}
     */
    isAdmin(callback?) {
        return this.getCurrentUser().then((user) => {
            const is = user.role === 'admin';
            safeCb(callback)(is);
            return is;
        });
    }

    isAdminSync() {
        return this.currentUser.role === 'admin';
    }

    getVersion() {
        return version;
    }

    setCurrentAccount(
        accountId?: string,
        state?: RouterStateSnapshot
    ): Promise<Account> {
        const self = this;
        const oldAccount = cloneDeep(self.currentAccount);
        return self.getCurrentUser().then((user) => {
            let newAccount;
            if (accountId && find(user.accounts, ['ref', accountId])) {
                newAccount = find(user.accounts, ['ref', accountId]);
            } else if (
                localStorage.getItem('currentAccount') &&
                find(user.accounts, [
                    'ref',
                    localStorage.getItem('currentAccount'),
                ])
            ) {
                //Get account from url
                newAccount = find(user.accounts, [
                    'ref',
                    localStorage.getItem('currentAccount'),
                ]);
            } else if (user.accounts && user.accounts.length > 0) {
                //Take first account
                newAccount = user.accounts[0];
            }
            if (newAccount) {
                self.currentAccount = newAccount;
                localStorage.setItem('currentAccount', newAccount.ref);

                //Check if account changed and reload
                if (newAccount.ref != accountId) {
                    if (state) {
                        const newUrl = state.url.replace(
                            accountId,
                            newAccount.ref
                        );
                        this.router.navigateByUrl(newUrl);
                    }
                }
                this.abilityRules = defineAbilityForAccount(
                    user,
                    self.currentAccount
                );

                return self.currentAccount;
            } else {
                console.error('User has no accounts');
                //Redirect to account registration?
                return null;
            }
        });
    }
}
