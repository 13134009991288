/*eslint no-process-env:0*/
const { version } = require('../../../package.json');

let googleAPIKeys = {
    maps: 'AIzaSyBW-wuFGTjSHk_gg20p9Fsvz9vQ6RAIN5E',
};

if (process.env.NODE_ENV === 'production') {
    googleAPIKeys = {
        maps: 'AIzaSyAycO-sU9d4v4j7lJBmydaelSmzpirIYXg',
    };
}

const EventTypes = [
    'Auth',
    'DoorLocked',
    'DoorUnlocked',
    'DoorOpened',
    'DoorClosed',
    'DoorForcedOpen',
    'Armed',
    'PartialArmed',
    'Disarmed',
    'PartialDisarmed',
    'UnitTamper', // Maybe later
    'LockTamper', // Maybe later
    'Duress',
];

export { version };
export { googleAPIKeys };
export { EventTypes };
export const env = process.env.NODE_ENV;
export const port = process.env.PORT || 9000;
// List of user roles
export const userRoles = ['guest', 'user', 'admin'];
export const policyUpdated = {
    privacy: new Date('2021-06-28T00:00:00.000Z'),
    disclaimer: new Date('2021-06-28T00:00:00.000Z'),
    terms: new Date('2021-06-28T00:00:00.000Z'),
};

export default {
    env,
    port,
    userRoles,
    policyUpdated,
    version,
    googleAPIKeys,
    EventTypes,
};
